// src/pages/services/pubg_mobile/MetroEscort.js
import React, {useEffect} from 'react';
import Header from '../../../components/Header';
import UnderHeader from '../../../components/UnderHeader';
import Footer from '../../../components/Footer';
import ServiceInfoCard from '../../../components/ServiceInfoCard';

function MetroEscort(){
    useEffect(() => {
        window.scrollTo(0, 0); // Прокрутка страницы до начала
      }, []);

      const handleAddToCart = (service) => {
        fetch('https://buferka.shop/cart/add', { // Убедитесь, что путь совпадает с сервером
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ item: service }),
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log('Added to cart:', data);
        })
        .catch((error) => {
          console.error('Error adding to cart:', error);
        });
      };
   
      return (
        <div className="service-page">
          <Header/>
          <UnderHeader 
          title="Metro Escort">
          </UnderHeader>
          
          <div className="services"> 
          <ServiceInfoCard
          title="Metro Escort 3"
          description="3 карта"
          price="2 &#36;"
          onAddToCart={() => handleAddToCart({ title: 'Metro Escort 3', description: '3 карта', price: '3' })}
        />        
        <ServiceInfoCard
          title="Metro Escort 5"
          description="5 карта"
          price="5 &#36;"
          onAddToCart={() => handleAddToCart({ title: 'Metro Escort 5', description: '5 карта', price: '5' })}
        />        
        <ServiceInfoCard
          title="Metro Escort 7"
          description="7 карта"
          price="7 &#36;"
          onAddToCart={() => handleAddToCart({ title: 'Metro Escort 7', description: '7 карта', price: '7' })}
        />        
          </div>
          <Footer/>
        </div>
      );
    }
export default MetroEscort;