// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import RustPage from './pages/RustPage';
import PubgPage from './pages/PubgPage';
import CartPage from './pages/CartPage';
import MetroEscort from './pages/services/pubg_mobile/MetroEscort';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/rust" element={<RustPage />} />
        <Route path="/pubg" element={<PubgPage />} />
        <Route path="/cart" element={<CartPage />} />
          <Route path="/pubg/escort-metro" Component={MetroEscort} />
          {/* <Route path="/pubg/metro-shop" component={MetroShop} />
          <Route path="/pubg/uc-shop" component={UCShop} /> */}
      </Routes>
    </Router>
  );
}

export default App;