// src/components/ServiceInfoCard.js
import React from 'react';
import './ServiceCard.css';
import './ServiceInfoCard.css';


const ServiceInfoCard = ({ title, description, price, onAddToCart }) => {
  return (
    <div className="service-info-card">
      <div className="service-info-content">
        <h2 className="service-info-title">{title}</h2>
        <p className="service-info-description">{description}</p>
        <div className="service-info-footer">
          <span className="service-info-price">{price}</span>
          <button className="info-button" style={{maxWidth: '200px'}} onClick={onAddToCart}>
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceInfoCard;
