// src/pages/CartPage.js
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './CartPage.css';

const CartPage = () => {
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    // Получение данных корзины с сервера
    fetch('https://buferka.shop/api/cart/view', {
      method: 'GET',
      credentials: 'include',  // Включить куки для аутентификации
    })
      .then(response => response.json())
      .then(data => {
        setCart(data.cart.items || []);
        calculateTotalPrice(data.cart.items || []);
      })
      .catch(error => console.error('Error fetching cart:', error));
  }, []);

  const calculateTotalPrice = (items) => {
    const total = items.reduce((sum, item) => sum + parseFloat(item.price), 0);
    setTotalPrice(total);
  };

  return (
    <div className="cart-page">
      <Header />
      <h1>Корзина</h1>
      <div className="cart-items">
        {cart.length === 0 ? (
          <p>Корзина пуста.</p>
        ) : (
          <ul>
            {cart.map((item, index) => (
              <li key={index}>
                <strong>{item.title}</strong> - {item.description} - {item.price} у.е.
              </li>
            ))}
          </ul>
        )}
      </div>
      <h2>Общая стоимость: {totalPrice} у.е.</h2>
      <Footer />
    </div>
  );
};

export default CartPage;
